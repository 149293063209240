import { isObject } from './isObject';

/**
 * Check equality of array or object, deeply by default.
 * @param {Object|Array} object1
 * @param {Object|Array} object2
 * @param {boolean} [deep = true]
 */
export function isEqual(object1: unknown, object2: unknown, deep = true): boolean {
  if (!iterable(object1, object2)) return object1 === object2;

  if (Array.isArray(object1) && Array.isArray(object2)) {
    if (object1.length !== object2.length) return false; // saves times for arrays
  }

  const object1Keys = Object.keys(object1 as object);
  const object2Keys = Object.keys(object2 as object);
  return (
    !object1Keys.find((key) => mismatch(object1, object2, key)) &&
    !object2Keys.find((key) => mismatch(object2, object1, key))
  );
  function iterable(obj1: any, obj2: any) {
    return (Array.isArray(obj1) && Array.isArray(obj2)) || (isObject(obj1) && isObject(obj2));
  }
  function keysMismatch(obj1: any, obj2: any, key: any) {
    return !obj2.hasOwnProperty(key) || !obj1.hasOwnProperty(key);
  }
  function mismatch(obj1: any, obj2: any, key: any) {
    if (keysMismatch(obj1, obj2, key)) return true;
    if (deep && iterable(obj1[key], obj2[key])) return !isEqual(obj1[key], obj2[key], deep);
    return obj1[key] !== obj2[key];
  }
}
